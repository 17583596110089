<template lang='pug'>
head-meta(
  title-page="Реквизиты Музея «Шоколадный Выборг»",
  description="Вы можете воспользоваться нашими реквизитами для оформления платежных документов. Также можете скачать реквизиты в формате .pdf"
)
the-requisites
contacts-line
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import TheRequisites from '../components/requisites/TheRequisites'
import ContactsLine from '../components/_layout/TheContactsLine'
export default {
  name: 'RequisitesPage',
  components: { ContactsLine, TheRequisites, HeadMeta }
}
</script>
