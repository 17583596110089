<template lang='pug'>
.requisites
  .requisites__cont.cont
    h1.requisites__caption.caption Реквизиты
    .requisites__content
      p.requisites__text.text-big ООО «Привал»
      p.requisites__text.text-big ИНН: 4704050284
      p.requisites__text.text-big КПП: 470401001
      p.requisites__text.text-big ОГРН: 1024700881413
      p.requisites__text.text-big ОКПО: 11183794
      p.requisites__text.requisites__text_top-block.text-big Расчетный счет: 40702810385000000243
      p.requisites__text.text-big Банк: Ф. ОПЕРУ БАНКА ВТБ (ПАО) В САНКТ-ПЕТЕРБУРГЕ
      p.requisites__text.text-big КОРР. СЧЁТ: 30101810200000000704
      p.requisites__text.requisites__text_top-block.text-big Юридический адрес: 188800, Ленинградская область, Выборгский район, г. Выборг, Штурма ул., Пороховой погреб, литера А
      p.requisites__text.text-big Телефоны: 8 981 847 99 23
      p.requisites__text.text-big Генеральный директор: Салтанова Дана Андреевна
    a.requisites__link.button-white(href="/docs/requisites.pdf", download) Скачать pdf
</template>

<script>
import '@/assets/styles/components/requisites.sass'
export default {
  name: 'TheRequisites'
}
</script>
